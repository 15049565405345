body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --body-medium: "Noto Sans";

  /* font sizes */
  --body-medium-size: 18px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-xs: 12px;
  --font-size-xl: 20px;
  --h1-size: 56px;
  --font-size-13xl: 32px;
  --font-size-7xl: 26px;
  --font-size-base: 16px;

  /* Colors */
  --grey: #e5e5e5;
  --green-green: #005253;
  --green-dark-green: #00393a;
  --color-darkslategray-100: rgba(0, 57, 58, 0.2);
  --white: #fff;
  --black: #1b1c1c;
  --color-gray-100: rgba(255, 255, 255, 0.8);
  --color-black: #000;
  --green-lightest-green: #b4e1e7;
  --color-powderblue-100: rgba(180, 225, 231, 0.08);

  /* Gaps */
  --gap-41xl: 60px;
  --gap-11xl: 30px;
  --gap-21xl: 40px;
  --gap-xl: 20px;
  --gap-5xl: 24px;
  --gap-4xs: 9px;
  --gap-3xs: 10px;
  --gap-xs: 12px;
  --gap-2xl: 21px;
  --gap-lg: 18px;
  --gap-base: 16px;
  --gap-13xl: 32px;
  --gap-22xl-8: 41.8px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-61xl: 80px;
  --padding-21xl: 40px;
  --padding-88xl: 107px;
  --padding-xl: 20px;
  --padding-34xl: 53px;
  --padding-2xl: 21px;
  --padding-5xl: 24px;
  --padding-3xs: 10px;
  --padding-5xs: 8px;
  --padding-lg: 18px;
  --padding-11xs: 2px;
  --padding-4xl: 23px;
  --padding-36xl-2: 55.2px;
  --padding-36xl-3: 55.3px;
  --padding-12xl: 31px;
  --padding-699xl: 718px;
  --padding-340xl: 359px;
  --padding-160xl: 179px;
  --padding-730xl: 749px;
  --padding-355xl: 374px;
  --padding-168xl: 187px;
  --padding-mini: 15px;
  --padding-794xl: 813px;
  --padding-387xl: 406px;
  --padding-184xl: 203px;
  --padding-base: 16px;
}
